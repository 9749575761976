<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/systemset/merchantFeedback' }">商户端问题反馈</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row style="margin-top:20px">
                <el-col :span="16">
                    <el-form v-if="info.id" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="企业logo：">
                            <Qnupload v-model="info.logo" :readonly="true" />
                        </el-form-item>
                        <el-form-item label="企业名称：">
                            <span class="form-span-text">{{ info.company_name }}</span>
                        </el-form-item>
                        <el-form-item label="手机号：">
                            <span v-if="info.mobile" class="form-span-text">{{ info.mobile }}</span>
                            <span v-else class="form-span-text">无手机号</span>
                        </el-form-item>
                        <el-form-item label="反馈类型：">
                            <span class="form-span-text">{{ info.name }}</span>
                        </el-form-item>
                        <el-form-item label="问题描述：">
                            <span class="form-span-text">{{ info.content }}</span>
                        </el-form-item>
                        <el-form-item label="照片：">
                            <!-- 图片 -->
                            <section v-if="info.photos.length > 0">
                                <div style="width: 120px;height: 120px;margin-right: 24px;display: inline-block;"
                                    v-for="(item, index) in info.photos" :key="index">
                                    <el-image style="width: 100%; height: 100%" :src="item" fit="contain"
                                        :preview-src-list="info.photos">
                                    </el-image>
                                </div>
                            </section>
                            <section v-else>
                                <span>无照片</span>
                            </section>
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import Qnupload from '@/components/Qnupload'
export default {
    components: {
        Qnupload
    },
    data() {
        return {
            id: "",
            info: {
            }
        }
    },
    created() {
        let { id } = this.$route.query;
        if (id) {
            this.id = id;
            this.getInfo();
        }
    },
    methods: {
        // 获取问题反馈详情
        getInfo() {
            const { id } = this;
            let postdata = {
                api_name: "feedback.mfeedback.getfeedbackinfo",
                token: this.Tool.get_l_cache("token"),
                id,
            };
            this.loading = true;
            this.Tool.post_data("oss", postdata, (res) => {
                if (res.code === 0) {
                    this.info = res.data;
                } else if (res.code === 10005) {
                    this.Tool.errormes(res);
                }
                this.loading = false;
            });
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    },
}
</script>